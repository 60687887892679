<template>
  <div class="container">
    <div class="banner">
      <img src="../assets/images/contentImgs/banner-line.svg" />
    </div>
    <div class="innerBox">
      <div class="desc">
        <h1>- 关于我们 -</h1>
        <p>
          深圳市捷汇锐进出口有限公司创始于2005年成立。主营报关、进出口代理等服务。是经深圳海关和国家商务部批准，工商局依法登记注册的报关进出口公司，公司拥有自己报关行企业。是深圳较大型的深圳一手报关行企业。
        </p>
        <p>
          深圳市捷汇锐公司长期以来从事深圳盐田报关、报检，进口代理、出口买单报关业务，在深圳盐田报关报检业务方面具有超强优势价格。长期以来，我司培养了一批专著于深圳盐田报关、报检、进出口代理业务的专门人才，在深圳盐田报关报检方面具备了丰富的经验和良好的人脉关系
        </p>
        <p>
           "客户至上、服务专业、互惠互利、讲究信誉、价优"是捷汇锐公司的宗旨，"勤奋、务实、进取、持之以恒"是我们的信念，欢迎海內外朋友随时來电询问,我们将竭诚为您服务！
        </p>
        <p>
          服务口岸:
            <ul>
              <li>盐田港</li>
              <li>蛇口港</li>
              <li>黄埔港</li>
              <li>深圳机场</li>
              <li>皇岗口岸</li>
              <li>文锦渡口岸</li>
            </ul>
        </p>
      </div>
    </div>
    <div class="innerBox bg">
      <div class="desc">
        <h1>- 联系方法 -</h1>
        <div class="contactInfo">
          <div class="leftItem">
            <baidu-map/>
          </div>
          <div class="rightItem">
            <p>深圳市捷汇锐进出口有限公司</p>
            <p>地 址： 深圳市罗湖区金稻田路紫荆花园A栋30楼D</p>
            <p>邮 编： 518000</p>
            <p>电 话： +86-755-88859986</p>
            <p>邮 箱： sales1@szjhr.cn</p>
          </div>

        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import BaiduMap from "components/plugin/baiduMap/BaiduMap.vue";
export default {
  data() {
    return {};
  },
  components: { BaiduMap },
};
</script>
<style lang="less" scoped>
@import url("../assets/css/banner.less");
@import url("../assets/css/container.less");
.banner {
  background-image: linear-gradient(-35deg, #628fa3, #3e545d 51%, #003a6c);
}
.contactInfo {
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
    margin-right: 30px;
  }
  .leftItem {
    height: 350px;
    min-width: 350px;
  }
  .rightItem {
    > p {
      font-size: 16px;
      line-height: 20px;
    }
    > p:nth-child(1) {
      font-weight: bold;
      font-size: 25px;
      color: var(--color-menu);
    }
  }
}
@media screen and (max-width: 980px) {
  .contactInfo {
    display: flex;
    flex-direction: column-reverse !important;
    > div {
      margin: 0;
      .map {
        width: 100%;
      }
    }
  }
}
.desc {
  > p {
    font-size: 16px;
    line-height: 25px;
    color: #4a4a4a;
  }
}
</style>