<template>
  <baidu-map
    class="map"
    ak="ryeqA7FRtZ6jrOGWpLTCD0Uw"
    :center="center"
    :zoom="zoom"
  >
    <bm-marker
      :position="center"
      :dragging="true"
      animation="BMAP_ANIMATION_BOUNCE"
    >
      <bm-label
        content="捷汇锐"
        :labelStyle="{
          color: 'white',
          borderRadius: '5px',
          fontSize: '18px',
          background: 'var(--color-menu)',
          border: '1px solid var(--color-menu)',
        }"
        :offset="{ width: -25, height: 30 }"
      />
    </bm-marker>
  </baidu-map>
</template>
<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";

import { BmMarker, BmLabel } from "vue-baidu-map/components/index";
export default {
  data() {
    return {
      center: { lng: 114.123856, lat: 22.592926 },
      zoom: 15,
    };
  },
  methods: {},
  components: {
    BaiduMap,
    BmMarker,
    BmLabel,
  },
};
</script>

<style>
.map {
  width: 90%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 10px #999;
}
</style>